import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {sluger} from "../util/sluger"
 
const AppCard = ({ data }) => (
  <article className="app-card">
    {data.frontmatter.appIcon ? 
      ( 
      <div className="image-box">
        <Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/apps/")}>
          <Img 
            fluid={data.frontmatter.appIcon.childImageSharp.fluid} 
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + ' - icon image'}
            className="featured-image"
          />
        </Link>
        </div>
      ) : ""
    }
    <div className="post-content">
      <h2 className="title"><Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/apps/")}>{data.frontmatter.title}</Link></h2>
    </div>
  </article>
)

export default AppCard