import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

import Layout from "../components/layout"
import AppCard from "../components/app-card"
import SEO from "../components/seo"

export const appsQuery = graphql`
  query appsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { template: { eq: "app-preview" }, date: { ne: null } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            appIcon {
              childImageSharp {
                fluid(maxWidth: 192, maxHeight: 192, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`

const Pagination = props => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            Previous
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={i === 0 ? props.slugMainPrefix : `${props.slugPrefix}${i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Next{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const dataEx =  JSON.parse(JSON.stringify(data));
    const { currentPage, numPages } = this.props.pageContext
    const slugMainPrefix = "/apps/"
    const slugPrefix = "/apps/page/"
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? slugMainPrefix : slugPrefix + (currentPage - 1).toString()
    const nextPage = slugPrefix + (currentPage + 1).toString()

    const posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <AppCard key={edge.node.id} data={edge.node} />)

    let titles = ""
    dataEx.allMarkdownRemark.edges
      .reverse()
      .filter(edge => !!edge.node.frontmatter.date)
      .map((edge, i) => {
        var isLast = i + 1 === posts.length
        titles = titles + edge.node.frontmatter.title + (isLast ? "" : " | ")
        return titles
      })

    const descSEO =
      titles.length >= 160 ? titles.substr(0, 160) + "..." : titles

    let props = {
      isFirst,
      prevPage,
      numPages,
      slugMainPrefix,
      slugPrefix,
      currentPage,
      isLast,
      nextPage,
    }

    return (
      <Layout className="blog-page">
        <SEO 
        title={"Apps - Meek Code"}
        description={descSEO} />
        <div className="header-title">
          <h1>Apps</h1>
        </div>
        <div className="page-strict">
          <div className="grids col-1 sm-2 lg-4">{posts}</div>
        </div>
        <Pagination {...props} />
      </Layout>
    )
  }
}

export default BlogIndex
